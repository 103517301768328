<template>
  <div class="company_info">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="竣工验收" name="竣工验收">
        <el-table
          v-loading="loading"
          :data="list"
          border
          style="width: 100%"
          ref="personnel"
        >
          <el-table-column type="index" width="50"> </el-table-column>

          <el-table-column
            sortable
            prop="actualCost"
            min-width="80"
            :show-overflow-tooltip="true"
            label="实际造价"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.actualCost | noVoid }}万元</div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            prop="actualArea"
            min-width="80"
            :show-overflow-tooltip="true"
            label="实际面积"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.actualArea | noVoid }}平方米</div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            prop="structureSystem"
            min-width="80"
            :show-overflow-tooltip="true"
            label="结构体系"
          ></el-table-column>

          <el-table-column
            sortable
            prop="constructPermitCode"
            min-width="120"
            :show-overflow-tooltip="true"
            label="施工许可证编号"
          ></el-table-column>

          <el-table-column
            sortable
            prop="actualCompleteTime"
            min-width="80"
            :show-overflow-tooltip="true"
            label="实际竣工时间"
          ></el-table-column>

          <el-table-column
            sortable
            prop="dataLevel"
            min-width="80"
            :show-overflow-tooltip="true"
            label="数据等级"
          ></el-table-column>

          <el-table-column
            sortable
            min-width="60"
            :show-overflow-tooltip="true"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="link" @click="showDetail(scope.row)">查看</div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="pagination"
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="form.pageNum"
          :page-sizes="[20, 50, 100, 150, 200]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>

        <!-- 详情 -->
        <customDialog v-model="detailsShow" title="竣工验收详情" width="1120px">
          <div class="table">
            <div class="flex row">
              <div class="desc_item">
                <div class="label">实际造价（万元）</div>
                <div class="value">
                  {{ details.actualCost | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际面积（平方米）</div>
                <div class="value">
                  {{ details.actualArea | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">结构体系</div>
                <div class="value">
                  {{ details.structureSystem | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">施工许可证编号</div>
                <div class="value">
                  {{ details.constructPermitCode | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">记录登记日期</div>
                <div class="value">
                  {{ details.createTime | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际开工日期</div>
                <div class="value">
                  {{ details.actualStartTime | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">竣工验收编号</div>
                <div class="value">
                  {{ details.completeCode | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际竣工验收时间</div>
                <div class="value">
                  {{ details.actualCompleteTime | noVoid }}
                </div>
              </div>
            </div>

            <div class="desc_item row">
              <div class="desc_item">
                <div class="label">数据等级</div>
                <div class="value">
                  {{ details.dataLevel | noVoid }}
                </div>
              </div>
            </div>
          </div>
        </customDialog>
      </el-tab-pane>

      <el-tab-pane label="竣工验收备案" name="竣工验收备案">
        <el-table
          v-loading="recordLoading"
          :data="recordList"
          border
          style="width: 100%"
          ref="personnel"
        >
          <el-table-column type="index" width="50"> </el-table-column>

          <el-table-column
            sortable
            prop="recordCompleteProvinceCode"
            min-width="120"
            :show-overflow-tooltip="true"
            label="省级竣工备案编号"
          >
          </el-table-column>

          <el-table-column
            sortable
            prop="actualCost"
            min-width="80"
            :show-overflow-tooltip="true"
            label="实际造价"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.actualCost | noVoid }}万元</div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            prop="actualArea"
            min-width="80"
            :show-overflow-tooltip="true"
            label="实际面积"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.actualArea | noVoid }}平方米</div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            prop="actualCompleteRecordTime"
            min-width="140"
            :show-overflow-tooltip="true"
            label="实际竣工验收备案时间"
          ></el-table-column>

          <el-table-column
            sortable
            prop="constructPermitCode"
            min-width="120"
            :show-overflow-tooltip="true"
            label="竣工验收备案编号"
          ></el-table-column>

          <el-table-column
            sortable
            prop="constructPermitCode"
            min-width="120"
            :show-overflow-tooltip="true"
            label="施工许可证编号"
          ></el-table-column>

          <el-table-column
            sortable
            prop="dataLevel"
            min-width="80"
            :show-overflow-tooltip="true"
            label="数据等级"
          ></el-table-column>

          <el-table-column
            sortable
            min-width="60"
            :show-overflow-tooltip="true"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="link" @click="showRecordDetail(scope.row)">查看</div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="pagination"
          @size-change="recordSizeChange"
          @current-change="recordCurrentChange"
          :current-page="recordForm.pageNum"
          :page-sizes="[20, 50, 100, 150, 200]"
          :page-size="recordForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordTotal"
        >
        </el-pagination>

        <!-- 详情 -->
        <customDialog
          v-model="recordDetailsShow"
          title="竣工验收备案详情"
          width="1120px"
        >
          <div class="table">
            <div class="flex row">
              <div class="desc_item">
                <div class="label">省级竣工备案编号</div>
                <div class="value">
                  {{ recordDetails.recordCompleteProvinceCode | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">竣工验收备案编号</div>
                <div class="value">
                  {{ recordDetails.recordCompleteCode | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">施工许可证编号</div>
                <div class="value">
                  {{ recordDetails.constructPermitCode | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际造价（万元）</div>
                <div class="value">
                  {{ recordDetails.actualCost | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">实际面积（平方米）</div>
                <div class="value">
                  {{ recordDetails.actualArea | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际建设规模</div>
                <div class="value">
                  {{ recordDetails.actualConstructionScale | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">结构体系</div>
                <div class="value">
                  {{ recordDetails.structuralSystem | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">实际开工日期</div>
                <div class="value">
                  {{ recordDetails.actualStartTime | noVoid }}
                </div>
              </div>
            </div>

            <div class="flex row">
              <div class="desc_item">
                <div class="label">实际竣工验收备案时间</div>
                <div class="value">
                  {{ recordDetails.actualCompleteRecordTime | noVoid }}
                </div>
              </div>

              <div class="desc_item">
                <div class="label">数据来源</div>
                <div class="value">
                  {{ recordDetails.dataSource | noVoid }}
                </div>
              </div>
            </div>

            <div class="desc_item row">
              <div class="desc_item">
                <div class="label">备注</div>
                <div class="value">
                  {{ recordDetails.remark | noVoid }}
                </div>
              </div>
            </div>
          </div>
        </customDialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 四库详情-竣工验收 */
import {
  getSikuyiCompleteList,
  getSikuyiCompleteRecordList,
} from "@/api/fourthLibrary.js";

export default {
  props: ["basicId"],

  data() {
    return {
      activeName: "竣工验收",
      loading: false,
      recordLoading: false,
      list: [],
      total: 0,
      recordList: [],
      recordTotal: 0,
      form: {
        basicId: 0,
        pageNum: 1,
        pageSize: 20,
      },
      recordForm: {
        basicId: 0,
        pageNum: 1,
        pageSize: 20,
      },
      detailsShow: false,
      details: {},
      recordDetailsShow: false,
      recordDetails: {},
    };
  },

  watch: {
    basicId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取竣工验收列表数据
    async getData() {
      this.loading = true;
      const params = {
        ...this.form,
        basicId: this.basicId,
      };
      const { code, data } = await getSikuyiCompleteList(params);
      if (code !== 0) return;

      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },
    // 获取竣工验收备案列表数据
    async getRecordData() {
      this.recordLoading = true;
      const params = {
        ...this.form,
        basicId: this.basicId,
      };
      const { code, data } = await getSikuyiCompleteRecordList(params);
      if (code !== 0) return;

      this.recordList = data.records;
      this.recordTotal = data.total;
      this.recordLoading = false;
    },

    /* 操作 */
    // 查看详情-竣工
    showDetail(item) {
      this.details = { ...item };
      this.detailsShow = true;
    },
    // 查看详情-竣工备案
    showRecordDetail(item) {
      this.recordDetails = { ...item };
      this.recordDetailsShow = true;
    },
    // 切换tab时触发
    handleTabClick(tab) {
      this.activeName = tab.name;
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 当前页改变时-竣工验收备案
    recordCurrentChange(val) {
      this.recordForm.pageNum = val;
      this.getData();
    },
    // 当前显示页数改变时-竣工验收备案
    recordSizeChange(val) {
      this.recordForm.pageSize = val;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.getData();
      this.getRecordData();
    },
  },
};
</script>

<style lang="less" scoped>
.company_info {
  background-color: #fff;
  margin-bottom: 20px;
  color: #333;
  font-size: 14px;
  position: relative;

  .desc_item {
    flex: 1;
    display: flex;
    align-items: stretch;
    font-size: 14px;
    .label {
      padding: 15px;
      width: 200px;
      text-align: center;
      color: #666;
      display: flex;
      align-items: center;
      background-color: #f5faff;
    }

    .value {
      display: flex;
      align-items: center;
      padding: 15px;
      flex: 1;
      color: #666;
      border-right: 1px solid #ecf1ff;
      border-left: 1px solid #ecf1ff;
      line-height: 1.5;
    }
  }
  .row {
    border: 1px solid #ecf1ff;
    border-bottom: none;
    border-right: none;
    &:last-child {
      border-bottom: 1px solid #ecf1ff;
    }
  }

  /deep/.el-tabs__header,
  /deep/.el-tabs__content {
    background-color: #fff !important;
    padding: 0px !important;
    box-shadow: none !important;
  }

  /deep/.el-tabs__header {
    position: static !important;
  }
}
</style>
