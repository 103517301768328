// 企业详情模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 获取四库专查列表
export const querySikuyiBasicPage = (data) => {
  return request({
    url: '/api/sikuyiBasic/page',
    method: "post",
    data
  })
}

// 获取四库一基础信息
export const getSikuyiBasic = (params) => {
  return request({
    url: '/api/sikuyiBasic',
    method: "get",
    params
  })
}

// 招投标信息分页列表
export const getSikuyiResultList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiResult/page',
    method: "post",
    data
  })
}

// 参与单位及相关负责人分页列表
export const getSikuyiParticipateCompanyList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiParticipateCompany/page',
    method: "post",
    data
  })
}

// 合同登记信息分页列表
export const getSikuyiContractList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiContract/page',
    method: "post",
    data
  })
}

// 施工图审查分页列表
export const getSikuyiConstructReviewList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiConstructReview/page',
    method: "post",
    data
  })
}

// 施工图许可分页列表
export const getSikuyiConstructPermitList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiConstructPermit/page',
    method: "post",
    data
  })
}

// 竣工验收备案分页列表
export const getSikuyiCompleteRecordList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiCompleteRecord/page',
    method: "post",
    data
  })
}

// 竣工验收分页列表
export const getSikuyiCompleteList = (data) => {
  return request({
    url: '/api/sikuyiBasic/sikuyiComplete/page',
    method: "post",
    data
  })
}