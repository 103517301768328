<template>
  <div class="company_info">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="基本信息" name="基本信息" lazy>
        <div class="table">
          <div class="flex row">
            <div class="desc_item">
              <div class="label">项目代码</div>
              <div class="value">
                {{ details.resultCode | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">项目编号</div>
              <div class="value">
                {{ details.resultNo | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">项目分类</div>
              <div class="value">
                {{ details.resultType | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">行政区划</div>
              <div class="value">
                {{ details.location | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">具体地点</div>
              <div class="value">
                {{ details.specificLocation | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">经纬度</div>
              <div class="value">
                {{ details.longitudeLatitude | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">立项文号</div>
              <div class="value">
                {{ details.resultProjectNo | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">立项级别</div>
              <div class="value">
                {{ details.resultLevel | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">立项批复机关</div>
              <div class="value">
                {{ details.approvedInstitution | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">立项批复时间</div>
              <div class="value">
                {{ details.approvedTime | noVoid }}
              </div>
            </div>
          </div>

          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">建设单位</div>
              <div class="value">
                {{ details.ownerName | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">建设单位统一社会信用代码</div>
              <div class="value">
                {{ details.ownerCreditCode | noVoid }}
              </div>
            </div>
          </div>

          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">建设用地规划许可证编号</div>
              <div class="value">
                {{ details.landPermit | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">工程投资性质</div>
              <div class="value">
                {{ details.investmentType | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">项目二维码</div>
              <div class="value">
                {{ details.resultQr | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">资金来源</div>
              <div class="value">
                {{ details.amountSource | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">国有资金出资比例</div>
              <div class="value">
                {{ details.ownedCapital | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">总面积（平方米）</div>
              <div class="value">
                {{ details.totalArea | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">总投资（万元）</div>
              <div class="value">
                {{ details.totalAmount | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">总长度（米）</div>
              <div class="value">
                {{ details.totalLength | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">建设性质</div>
              <div class="value">
                {{ details.constructType | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">建设规模</div>
              <div class="value">
                {{ details.constructArea | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">重点项目</div>
              <div class="value">
                {{ details.majorProject | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">工程用途</div>
              <div class="value">
                {{ details.engineeringUse | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">计划开工</div>
              <div class="value">
                {{ details.planStartTime | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">计划竣工</div>
              <div class="value">
                {{ details.planEndTime | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">建筑节能信息</div>
              <div class="value">
                {{ details.energySaving | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">超限项目信息</div>
              <div class="value">
                {{ details.outOfLimit | noVoid }}
              </div>
            </div>
          </div>
          <div class="desc_item row">
            <div class="desc_item">
              <div class="label">数据来源</div>
              <div class="value">
                {{ details.dataSource | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">数据等级</div>
              <div class="value">
                {{ details.dataLevel | noVoid }}
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane
        label="参与单位及相关负责人"
        name="参与单位及相关负责人"
        lazy
      >
        <el-table
          v-loading="loading"
          :data="list"
          border
          style="width: 100%"
          ref="personnel"
        >
          <el-table-column type="index" width="50"> </el-table-column>

          <el-table-column
            sortable
            prop="companyRole"
            min-width="100"
            :show-overflow-tooltip="true"
            label="企业承担角色"
          >
          </el-table-column>

          <el-table-column
            sortable
            prop="companyName"
            min-width="100"
            :show-overflow-tooltip="true"
            label="企业名称"
          ></el-table-column>

          <el-table-column
            sortable
            prop="companyTaxPayer"
            min-width="100"
            :show-overflow-tooltip="true"
            label="企业统一社会信用代码"
          ></el-table-column>

          <el-table-column
            sortable
            prop="companyPerson"
            min-width="100"
            :show-overflow-tooltip="true"
            label="负责人姓名"
          ></el-table-column>

          <el-table-column
            sortable
            prop="companyPersonIdno"
            min-width="100"
            :show-overflow-tooltip="true"
            label="负责人证件号"
          ></el-table-column>
        </el-table>

        <el-pagination
          class="pagination"
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="form.pageNum"
          :page-sizes="[20, 50, 100, 150, 200]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 四库详情-工程基本信息 */
import { getSikuyiParticipateCompanyList } from "@/api/fourthLibrary.js";

export default {
  props: ["details"],

  data() {
    return {
      activeName: "基本信息",
      loading: false,
      list: [],
      total: 0,
      form: {
        basicId: 0,
        pageNum: 1,
        pageSize: 20,
        type: "join",
      },
    };
  },

  watch: {
    details: {
      handler(newVal) {
        if (newVal.basicId) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      this.form.basicId = this.details.basicId;
      const params = {
        ...this.form,
      };
      const { code, data } = await getSikuyiParticipateCompanyList(params);
      if (code !== 0) return;

      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 切换tab时触发
    handleTabClick(tab) {
      this.activeName = tab.name;
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.company_info {
  background-color: #fff;
  margin-bottom: 20px;
  color: #333;
  font-size: 14px;
  position: relative;

  .desc_item {
    flex: 1;
    display: flex;
    align-items: stretch;
    font-size: 14px;
    .label {
      padding: 15px;
      min-width: 200px;
      text-align: center;
      color: #666;
      display: flex;
      align-items: center;
      background-color: #f5faff;
    }

    .value {
      display: flex;
      align-items: center;
      padding: 15px;
      flex: 1;
      color: #666;
      border-right: 1px solid #ecf1ff;
      border-left: 1px solid #ecf1ff;
      line-height: 1.5;
    }
  }
  .row {
    border: 1px solid #ecf1ff;
    border-bottom: none;
    border-right: none;
    &:last-child {
      border-bottom: 1px solid #ecf1ff;
    }
  }

  /deep/.el-tabs__header,
  /deep/.el-tabs__content {
    background-color: #fff !important;
    padding: 0px !important;
    box-shadow: none !important;
  }

  /deep/.el-tabs__header {
    position: static !important;
  }
}
</style>
