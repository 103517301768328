<template>
  <div class="evaluate">
    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      ref="personnel"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="constructPermitProvinceCode"
        min-width="120"
        :show-overflow-tooltip="true"
        label="省级施工许可证编号"
      ></el-table-column>

      <el-table-column
        sortable
        prop="contractAmount"
        min-width="80"
        :show-overflow-tooltip="true"
        label="合同金额"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.contractAmount | noVoid }}万元</div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="constructArea"
        min-width="80"
        :show-overflow-tooltip="true"
        label="面积"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.contractAmount | noVoid }}平方米</div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="permitTime"
        min-width="80"
        :show-overflow-tooltip="true"
        label="发证日期"
      ></el-table-column>

      <el-table-column
        sortable
        prop="constructPermitCode"
        min-width="120"
        :show-overflow-tooltip="true"
        label="施工许可编号"
      ></el-table-column>

      <el-table-column
        sortable
        prop="dataLevel"
        min-width="80"
        :show-overflow-tooltip="true"
        label="数据等级"
      >
      </el-table-column>

      <el-table-column
        sortable
        min-width="60"
        :show-overflow-tooltip="true"
        label="操作"
      >
        <template slot-scope="scope">
          <div class="link" @click="showDetail(scope.row)">查看</div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 详情 -->
    <customDialog v-model="detailsShow" title="施工许可详情" width="1120px">
      <div class="table">
        <div class="flex row">
          <div class="desc_item">
            <div class="label">项目名称</div>
            <div class="value">
              {{ details.name | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">工程名称</div>
            <div class="value">
              {{ details.name | noVoid }}
            </div>
          </div>
        </div>

        <div class="flex row">
          <div class="desc_item">
            <div class="label">省级施工图审查合格书编号</div>
            <div class="value">
              {{ details.reviewProvinceCode | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">施工图审查合格书编号</div>
            <div class="value">
              {{ details.reviewCode | noVoid }}
            </div>
          </div>
        </div>

        <div class="flex row">
          <div class="desc_item">
            <div class="label">审查完成时间</div>
            <div class="value">
              {{ details.reviewTime | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">建设规模描述</div>
            <div class="value">
              {{ details.constructionScale | noVoid }}
            </div>
          </div>
        </div>

        <div class="flex row">
          <div class="desc_item">
            <div class="label">一次审查通过</div>
            <div class="value">
              {{ details.isFirstPass | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">一次审查时违反强制性标准数</div>
            <div class="value">
              {{ details.reviewViolateCount | noVoid }}
            </div>
          </div>
        </div>

        <div class="flex row">
          <div class="desc_item">
            <div class="label">一次审查时违反强制性标准条目</div>
            <div class="value">
              {{ details.reviewViolateItem | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">记录登记日期</div>
            <div class="value">
              {{ details.createTime | noVoid }}
            </div>
          </div>
        </div>

        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">是否联合审查</div>
            <div class="value">
              {{ details.isJointReview | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">施工图审查机构名称</div>
            <div class="value">
              {{ details.reviewBodyName | noVoid }}
            </div>
          </div>
        </div>

        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">审图机构统一社会信用代码</div>
            <div class="value">
              {{ details.reviewBodyCode | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">消防设计审核时间</div>
            <div class="value">
              {{ details.fireReviewTime | noVoid }}
            </div>
          </div>
        </div>
        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">消防审查合格书编号</div>
            <div class="value">
              {{ details.fireReviewCode | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">消防审查机构</div>
            <div class="value">
              {{ details.fireReviewBody | noVoid }}
            </div>
          </div>
        </div>
        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">人防设计审核时间</div>
            <div class="value">
              {{ details.defenseReviewTime | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">人防审查合格书编号</div>
            <div class="value">
              {{ details.defenseReviewCode | noVoid }}
            </div>
          </div>
        </div>
        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">人防审查机构</div>
            <div class="value">
              {{ details.defenseReviewBody | noVoid }}
            </div>
          </div>

          <div class="desc_item">
            <div class="label">数据来源</div>
            <div class="value">
              {{ details.dataSource | noVoid }}
            </div>
          </div>
        </div>
        <div class="desc_item row">
          <div class="desc_item">
            <div class="label">数据等级</div>
            <div class="value">
              {{ details.dataLevel | noVoid }}
            </div>
          </div>
        </div>
      </div>
    </customDialog>
  </div>
</template>

<script>
/* 四库详情-施工许可 */
import { getSikuyiConstructPermitList } from "@/api/fourthLibrary.js";

export default {
  props: ["basicId"],

  // 数据
  data() {
    return {
      form: {
        basicId: 0,
        pageNum: 1,
        pageSize: 20,
      },
      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      details: {}, // 详情数据
      detailsShow: false, // 详情显示
    };
  },

  watch: {
    basicId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const params = {
        ...this.form,
        basicId: this.basicId,
      };

      const { code, data } = await getSikuyiConstructPermitList(params);
      if (code !== 0) return;
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 跳转详情
    showDetail(item) {
      this.details = { ...item };
      this.detailsShow = true;
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.desc_item {
  flex: 1;
  display: flex;
  align-items: stretch;
  font-size: 14px;
  .label {
    padding: 15px;
    width: 200px;
    text-align: center;
    color: #666;
    display: flex;
    align-items: center;
    background-color: #f5faff;
  }

  .value {
    display: flex;
    align-items: center;
    padding: 15px;
    flex: 1;
    color: #666;
    border-right: 1px solid #ecf1ff;
    border-left: 1px solid #ecf1ff;
    line-height: 1.5;
  }
}
.row {
  border: 1px solid #ecf1ff;
  border-bottom: none;
  border-right: none;
  &:last-child {
    border-bottom: 1px solid #ecf1ff;
  }
}
</style>
