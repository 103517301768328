<template>
  <div class="top_basic">
    <div class="title mb20">
      {{ details.name | noVoid }}
    </div>

    <div class="table">
      <div class="flex row">
        <div class="desc_item">
          <div class="label">项目编号</div>
          <div class="value">
            {{ details.resultNo | noVoid }}
          </div>
        </div>

        <div class="desc_item">
          <div class="label">省级项目编号</div>
          <div class="value">
            {{ details.resultProvinceNo | noVoid }}
          </div>
        </div>
      </div>

      <div class="flex row">
        <div class="desc_item">
          <div class="label">建设单位</div>
          <div class="value">
            {{ details.ownerName | noVoid }}
          </div>
        </div>

        <div class="desc_item">
          <div class="label">建设单位统一社会信用代码</div>
          <div class="value">
            {{ details.ownerCreditCode | noVoid }}
          </div>
        </div>
      </div>

      <div class="flex row">
        <div class="desc_item">
          <div class="label">项目分类</div>
          <div class="value">
            {{ details.resultType | noVoid }}
          </div>
        </div>

        <div class="desc_item">
          <div class="label">建设性质</div>
          <div class="value">
            {{ details.constructType | noVoid }}
          </div>
        </div>
      </div>

      <div class="flex row">
        <div class="desc_item">
          <div class="label">总面积（平方米）</div>
          <div class="value">
            {{ details.totalArea | noVoid }}
          </div>
        </div>

        <div class="desc_item">
          <div class="label">总投资（万元）</div>
          <div class="value">
            {{ details.totalAmount | noVoid }}
          </div>
        </div>
      </div>

      <div class="flex row">
        <div class="desc_item">
          <div class="label">立项级别</div>
          <div class="value">
            {{ details.resultLevel | noVoid }}
          </div>
        </div>

        <div class="desc_item">
          <div class="label">立项文号</div>
          <div class="value">
            {{ details.resultProjectNo | noVoid }}
          </div>
        </div>
      </div>

      <div class="desc_item row">
        <div class="label">信息来源</div>
        <div class="value">
          {{ details.sourceDesc | noVoid }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 企业详情-工商信息 */

export default {
  props: ["details"],
};
</script>

<style lang="less" scoped>
.top_basic {
  background-color: #fff;
  padding: 20px;

  .desc_item {
    flex: 1;
    display: flex;
    align-items: stretch;
    font-size: 14px;
    .label {
      padding: 15px;
      min-width: 200px;
      text-align: center;
      color: #666;
      display: flex;
      align-items: center;
      background-color: #f5faff;
    }

    .value {
      display: flex;
      align-items: center;
      padding: 15px;
      flex: 1;
      color: #666;
      border-right: 1px solid #ecf1ff;
      border-left: 1px solid #ecf1ff;
      line-height: 1.5;
    }
  }
  .row {
    border: 1px solid #ecf1ff;
    border-bottom: none;
    border-right: none;
    &:last-child {
      border-bottom: 1px solid #ecf1ff;
    }
  }
}
</style>
